/* PromotionBanner.css */
.promotion-banner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 90%; /* Adjust width for responsiveness */
    max-width: 550px; /* Adjust maximum width for larger screens */
}

.banner-content {
    display: flex;
    flex-direction: column; /* Stack elements vertically on mobile */
    align-items: center;
}

.close-button {
    background: #000;
    border: none;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
}

.close-button svg {
    width: 24px;
    height: 24px;
    fill: #888;
}

.promotion-image {
    max-width: 100%;
    max-height: 450px; /* Adjust height as needed */
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

.glassmorphism {
    background-color: rgba(232, 233, 183, 0.2); /* Adjust opacity as needed */
    backdrop-filter: blur(30px); /* Adjust blur amount as needed */
}

@media screen and (max-width: 768px) {
    .promotion-banner {
        max-width: 350px; /* Adjust maximum width for larger screens */
    }
}