.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, box-shadow 0.3s;
    z-index: 1000;
}

.navbar:hover {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 40px; /* Adjust the size as needed */
    transition: transform 0.3s;
}

.logo-container:hover .logo {
    transform: scale(1.1);
}
